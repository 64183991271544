@import '../../../styles/vendor/hel-components/variables';

.action {
  .box {
    margin: 0;
    background-color: $white;
  }
  margin-left: 0;
  margin-right: 0;
  padding-bottom: 15px;
  position: relative;
}
.action-title {
  position: relative;
  font-weight: 600;
  width: 100%;
  min-height: 31px;
  &-input {
    margin-right: 15px;
  }
}
.action-title-sticky {
  z-index: 7;
  background-color: $gray-lighter;
  padding: 0 67px;
  left: 0px !important;
  margin-left: 0;
  width: 100% !important;
  border-bottom: 1px solid #cfcfcf;
}

.validation-bar-open {
  .action-title-sticky {
    z-index: 7;
    background-color: $gray-lighter;
    padding: 0 67px;
    left: 0px !important;
    margin-left: 0;
    width: 75% !important;
    border-bottom: 1px solid #cfcfcf;
  }
}
.records.box {
  padding: 0 10px;
  background-color: $white;
  margin-top: -1px;

  h4 {
    font-size: 18px;
  }
  .attribute {
    padding: 15px;
  }
}
.attribute-labels-container {
  clear: both;
  margin-left: 15px;
}
.attribute-label {
  text-transform: uppercase;
  font-size: 0.8em;
}

.edit-action-type__input {
  &.form-control {
    z-index: 9;
    padding: 0;
    height: 41px;
  }
  height: 31px;
  padding: 0;
  border: 0;

  .Select-control {
    height: 41px;
    border-radius: 2px;
  }
}

.action-buttons {
  position: absolute;
  right: 15px;
  top: 15px;
  font-size: 14px;
  .action-dropdown-button {
    right: 0;
    top: 0;
    position: relative;
  }
}
.action-attributes {
  display: inline-block;
  margin-bottom: 0;
  width: 100%;

  .attribute {
    border: none;
  }
  .table-key {
    font-weight: 700;
  }
}

.action-open {
  .basic-attributes {
    display: inline-block;
    width: 100%;
  }
  .list-group-item {
    padding: 10px 15px;
  }
}
