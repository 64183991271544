@import "../../styles/vendor/hel-components/variables";

.popup-outer-background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  z-index: 999;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.8);
  overflow-y: scroll;
}

.popup-inner-background {
  background-color: white;
  margin: 10vh 0 0 6vw;
  width: 88vw;
  padding: 35px;
  position: relative;
}

.popup__close {
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: $gray-light;
  font-size: 2rem;
}

.has-children-text {
  color: red;
  margin: 30px 0;
}

.popup-buttons {
  button {
    margin: 0px 7px 10px 7px;
  }
}
