.not-found-container > p,
.not-found-container > h1 {
  text-align: center;
}

.not-found-container h1 {
  margin-top: 20rem;
}

.helsinki-logo {
  left: 5rem;
  position: absolute;
  top: 5rem;
  width: 15rem;
  margin: 5rem;
}
