@import '../../../styles/vendor/hel-components/variables';

.bulk-update-create {
  padding: 15px 0;

  .bulk-update-create-search {
    padding-right: 30px;
  }

  .bulk-update-create-actions {
    display: flex;
    flex-direction: column;
    button:not(:first-child) {
      margin-top: 15px;
    }
  }
  
  .bulk-update-create-side-content {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: $black05;
    border: 1px solid $black50;
  }

  .popup-inner-background {
    .alert-danger {
      padding: 15px;
    }

    button {
      margin-right: 15px;
      margin-top: 15px;
    }
  }
}
