@import '../../../styles/vendor/hel-components/variables';

.single-tos-container {
  background-color: white;
  padding: 0;

  .single-tos-header-wrapper {
    position: relative;
    z-index: 9;
    padding: 15px 20px 5px;
    background: white;
    border-bottom: solid 1px $gray-dark;
  }
}
.classification-header {
  padding-bottom: 20px;
  width: 100%;

  .list-group-item {
    margin: -1px 0 0 -1px;
  }
}
.single-tos-header {
  z-index: 11;
  h3 {
    margin: 0 0 10px;
    a {
      font-size: smaller;
    }
  }
  .btn {
    margin-left: 15px;
  }
}
.single-tos-wrapper {
  display: flex;
  > div {
    flex-grow: 1;
  }
}
.single-tos-content {
  padding: 15px 15px 150px;
}
.tos-version-data {
  .list-group-item {
    margin: -1px 0 0 -1px;
    color: $list-group-link-color;
  }
}
.tos-metadata-header {
  padding-top: 20px;
}
.tos-metadata {
  padding-bottom: 40px;
}
.validation-bar-container {
  background-color: $gray-lighter;
  border-left: 1px solid $gray-dark;
  z-index: 8;
}
.box {
  padding: 15px;
  border: 1px solid $gray-dark;
  border-radius: 4px;
}
.tos-nav {
  margin: 0 -15px 40px -15px;
  padding: 0 15px;
}
.phases-title {
  margin: 0 0 5px 0;
}
.black-icon, .black-icon:hover {
  color: black;
  font-size: 1.2em;
  text-decoration: none;
}
.document-buttons .col-xs-12 {
  padding-top: 5px;
}
.document-buttons > .btn {
  margin: 4px;
}
.button-row {
  padding: 0px 33px 0px 0px ;
}
.button-row .btn {
  margin: 0 1px;
}
.unlocated-title {
  color: black;
}
.required-asterisk {
  margin-left: 4px;
  color: black;
}
.required-legend {
  position: absolute;
  top: 10px;
  left: 15px;
}
.metadata-buttons {
  position: absolute;
  right: 20px;
  top: 15px;
  z-index: 1;

  .action-dropdown-button {
    right: 32px;
    top: 0;
  }
}

.metadata-data-row__secondary,
.metadata-data-row__primary {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .attribute-basic {
    margin: -1px 0 0 -1px;
  }
}
.metadata-data-row__secondary {
  clear: both;
}
.action-dropdown-button {
  position: absolute;
  right: 25px;
  top: 4px;
}
.btn-delete {
  color: #fff;
  background-color: #ff4e1e;
  border-color: #ff4e1e;
}
.btn-delete:hover {
  color: #fff;
  background-color: #c4340d;
  border-color: #c4340d;
}

.basic-attributes {
  padding-bottom: 20px;
}
.datepicker-field {
  color: #555555;

  strong {
    display: block;
  }
}

.cancelEditView {
  h3 {
    margin-bottom: 25px;
  }
  
  button {
    margin-right: 15px;
  }
}

.classification-header-text {
  display: flex;
  min-height: 96px;
  align-items: center;
}

.single-tos-header-sticky {
  z-index: 9;
}