@import '../../../styles/vendor/hel-components/variables';

$attribute-input-height: 31px;

.attribute {
  padding: 15px;
  margin: -1px 0 0 -1px;
  background-color: transparent;

  .table-value {
    line-height: 31px;
    word-wrap: break-word;
    min-height: 31px;
  }
}

.basic-attributes {
  vertical-align: middle;
  .form-control {
    z-index: 9;
    height: 34px;
  }

  .basic-attribute {
    min-height: 23px;
    padding: 0;
    font-size: 18px;
    font-weight: $bold;
    line-height: 34px;
    vertical-align: middle;
  }
}

.list-group-item {
  &.attribute {
    min-height: 62px;
    position: relative;
  }
}

.edit-attribute__input {
  margin-bottom: -10px;
  height: $attribute-input-height;
  padding: 0;
  border: 1px solid #dce4ec;

  .Select-control {
    height: $attribute-input-height;
    border-radius: 2px;
  }
}

.Select--single > .Select-control .Select-value {
  line-height: $attribute-input-height;
}

.Select-input {
  height: $attribute-input-height;
}

.Select-clear {
  margin-bottom: -4px;
}

.Select--multi {
  height: auto;
}

.Select-menu {
  max-height: 12em;
  overflow: scroll;
}
.Select-menu-outer {
  z-index: 1000;
}

.phase-open,
.action-open,
.record-open {
  .basic-attributes {
    padding-bottom: 0;
  }
}
