@import 'base';
@import 'vendor/hel-components/variables';

// Some best-practice CSS that's useful for most apps
// Just remove them if they're not what you want
html {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

.noscroll {
  overflow: hidden;
}

button:active {
  outline: none;
  border: none;
}
button:focus {
  outline: none;
  border: none;
}

.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button {
  text-transform: none;
}

.confirm-toastr-component {
  display: flex;
  flex-direction: row;
  border-top: 10px solid $brand-danger;
  padding: 15px;

  i {
    margin-right: 15px;
    color: $brand-danger;
  }
}
