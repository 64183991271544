// Flatly 3.2.0
// Bootswatch
// -----------------------------------------------------

@import url($web-font);

// Navbar =====================================================================

.navbar {
  border-width: 0;
}
.navbar-default {

  .badge {
    background-color: #fff;
    color: $navbar-default-bg;
  }
}

.navbar-inverse {

  .badge {
    background-color: #fff;
    color: $navbar-inverse-bg;
  }
}

.navbar-brand {
  padding: 18.5px 15px 20.5px;
  font-weight: $bold;
    font-family: $font-family-serif;
}
// Buttons ====================================================================

.btn-lg {
  padding: 10px 20px;
}

.btn:active {
  @include box-shadow(none);
  outline: none;
  border: none;
}

.btn:focus {
  outline: none;
  border: none;
}

.btn-group.open .dropdown-toggle {
  @include box-shadow(none);
}

// Typography =================================================================

.text-primary,
.text-primary:hover {
  color: $brand-primary;
}

.text-success,
.text-success:hover {
  color: $brand-success;
}

.text-danger,
.text-danger:hover {
  color: $brand-danger;
}

.text-warning,
.text-warning:hover {
  color: $brand-warning;
}

.text-info,
.text-info:hover {
  color: $brand-info;
}

.label {
    padding: .2em .4em .2em .4em;
}

// Tables =====================================================================

table,
.table {

  //a:not(.btn) {
  //  text-decoration: underline;
  //}

  .success,
  .warning,
  .danger,
  .info {
    color: #fff;

    a {
      color: #fff;
    }
  }

  > thead > tr > th,
  > tbody > tr > th,
  > tfoot > tr > th,
  > thead > tr > td,
  > tbody > tr > td,
  > tfoot > tr > td {
    border: none;
  }

}

.table-bordered > thead > tr > th,
.table-bordered > tbody > tr > th,
.table-bordered > tfoot > tr > th,
.table-bordered > thead > tr > td,
.table-bordered > tbody > tr > td,
.table-bordered > tfoot > tr > td {
  border: 1px solid $table-border-color;
}

// Forms ======================================================================

.form-control, input {
  border-width: 2px;
  @include box-shadow(none);

  &:focus {
    @include box-shadow(none);
  }
}

.has-warning {
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  .form-control-feedback {
    color: $brand-warning;
  }

  .form-control,
  .form-control:focus {
    border: 2px solid $brand-warning;
  }

  .input-group-addon {
    border-color: $brand-warning;
  }
}

.has-error {
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  .form-control-feedback {
    color: $brand-danger;
  }

  .form-control,
  .form-control:focus {
    border: 2px solid $brand-danger;
  }

  .input-group-addon {
    border-color: $brand-danger;
  }
}

.has-success {
  .help-block,
  .control-label,
  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline,
  .form-control-feedback {
    color: $brand-success;
  }

  .form-control,
  .form-control:focus {
    border: 2px solid $brand-success;
  }

  .input-group-addon {
    border-color: $brand-success;
  }
}

// Navs =======================================================================

.nav {
  .open > a,
  .open > a:hover,
  .open > a:focus {
    border-color: transparent;
  }
}

.pager {
  a,
  a:hover {
    color: #fff;
  }

  .disabled {
    &>a,
    &>a:hover,
    &>a:focus,
    &>span {
      background-color: $pagination-disabled-bg;
    }
  }
}

// Indicators =================================================================

.close {
  color: #fff;
  text-decoration: none;
  opacity: 0.4;

  &:hover,
  &:focus {
    color: #fff;
    opacity: 1;
  }
}

.alert {

  font-family: $font-family-serif;
  .alert-link {
    color: #fff;
    text-decoration: underline;
  }
}

// Progress bars ==============================================================

.progress {
  height: 10px;
  @include box-shadow(none);
  .progress-bar {
    font-size: 10px;
    line-height: 10px;
  }
}

// List groups ==============================================================


.list-group-item-heading {
  text-transform: uppercase;
  font-family: $font-family-serif; 
}


// Containers =================================================================

.well {
  @include box-shadow(none);
}

.panel-default {
  .close {
    color: $text-color;
  }
}

.panel-title {
  text-transform: uppercase;
  font-family: $font-family-serif; 
}


.modal {
  .close {
    color: $text-color;
  }
}

.popover {
  color: $text-color;
}