@import '../../../styles/vendor/hel-components/variables';

.validation-bar {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 25%;
  display: flex;
  flex-direction: column;

  .validation-bar-header {
    flex: 0 0 auto;
    padding: 0 15px;
    background-color: $gray-lighter;
    border-left: 1px solid $gray-dark;
  }

  .missing-attributes {
    margin-bottom: 25px;
  }

  .missing-attribute {
    .fa {
      color: $brand-danger;
    }
  }

  .warn-attribute {
    .fa {
      color: $brand-warning;
    }
  }

  .sidebar-close-button {
    background: transparent;
    border: none;
    font-size: $font-size-large;
  }

  .sidebar-filter {
    margin-top: 15px;
    margin-bottom: 5px;

    .btn {
      margin-right: 15px;
      border-radius: 15px;
    }

    .btn:not(.btn-default) {
      background-color: transparent;
      border: none;
      color: $black00;
    }

    .sidebar-filter-warn {
      .fa {
        color: $brand-warning;
      }
    }

    .sidebar-filter-error {
      .fa {
        color: $brand-danger;
      }
    }
  }

  .sidebar-content {
    overflow-y: scroll;
    flex: 1 1 auto;
    padding: 0 15px;

    h4 {
      margin-bottom: 20px;
    }

    h5 {
      margin: 0 0 10px;
      color: $link-color;
      
      &:hover {
        cursor: pointer;
      }
    }

    .sidebar-invalid-content {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    .sidebar-content-phase {
      > .parent-name {
        font-weight: 700;
      }
    }

    .parent-name {
      color: $link-color;
      
      &:hover {
        cursor: pointer;
      }
    }

    .element-name {
      margin-bottom: 5px;
    }

    .no-missing-attributes {
      display: flex;
      justify-content: center;
      padding: 20px 0;
      color: #009c3d;
      font-size: 120px;
    }

    .sidebar-content-action,
    .sidebar-content-record {
      padding-left: 15px;
    }
  }
}
