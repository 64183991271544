@import '../../../styles/vendor/hel-components/variables';

.bulk-view {
  padding: 15px 30px;
  width: 100%;

  .alert .fa {
    margin-right: 10px;
  }

  .bulk-view-changes-header {
    display: flex;
    padding-bottom: 15px;
    padding-top: 30px;

    .bulk-view-changes {
      flex: 1 1 auto;
    }
    
    button {
      margin-left: 15px;
    }
  }

  .bulk-view-items {
    border-top: 1px solid $hr-border;
  }

  .bulk-view-item {
    display: flex;
    padding: 15px 0;
    border-bottom: 1px solid $hr-border;

    &-changes {
      h4 span {
        color: #999;
        text-decoration: line-through;
      }
    }

    &-info {
      flex: 1 1 auto;
    }

    &-path {
      display: block;
      color: #999;
    }

    &-state,
    &-action {
      padding: 0 15px;
    }

    &-phase-error {
      color: $brand-danger;
    }

    .fa {
      margin-right: 10px;
    }
  }

  .bulk-view-name {
    font-weight: normal;
  }

  .popup-inner-background {
    button {
      margin-right: 15px;
    }
  }
}