.reorder-list {
  max-height: 65vh;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 35px;
  min-width: 300px;
}
.reorder-subtext {
  margin-bottom: 35px;
  color: black;
  font-size: 1.1em;
}

.reorder-label {
  margin-right: 30%;
}
