@import '../../../../styles/vendor/hel-components/variables';

.search-results {
  padding: 15px;
}

.search-result-header {
  padding: 15px;
  border-bottom: 1px solid $hr-border;

  &-amounts {
    display: flex;
    h4 {
      margin-right: 15px;
    }
  }
}

.search-result-item {
  padding: 15px;
  border-bottom: 1px solid $hr-border;
}

.search-result-item-check {
  display: inline-block;
  color: $hr-border;
  padding: 8px 10px;
  margin-top: 15px;
  border: 1px solid $hr-border;
}

.search-result-item-checked {
  background-color: $brand-primary;
  border: 1px solid $brand-primary;
  color: $white;
}

.search-result-item-path {
  display: block;
  color: #999;
}

.search-result-item-name {
  font-weight: normal;
}

.search-result-item-check:hover {
  cursor: pointer;
}