@import '../../styles/vendor/hel-components/variables';

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  right: 50%;
  z-index: 9999;
  background: #fff;
  padding: 10px;
  border-radius: $border-radius-base;
  box-shadow: 0 0 5px rgba(0, 0, 0, .3);
  margin: -15px -15px 0 0;

  .loader {
    font-size: 30px;
    color: $brand-primary;
  }
}

@keyframes loader {
  0% {
    transform: rotate(1deg);
  }
  50% {
    transform: rotate(160deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
