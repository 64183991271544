@import '../../../styles/vendor/hel-components/variables';

.importable-elements {
  height: 100%;
  height: 65vh;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px 0px 1px 1px solid #ededed;
  padding-top: 10px
}
.importable-element-link {
  padding: 10px 0;
}
.importable-element-link:hover {
  cursor: pointer;
  color: $link-color;
}
.import-wrapper {
  display: block;
  padding: 10px 0;
}
.import-row-title {
  display: block;
  padding: 10px 0;
}
.import-row-link:hover {
  cursor: pointer;
  color: $link-color;
}
.import-phase-title {
  display: block;
}
.import-action-record-wrapper {
  display: block;
  padding-left: 20px;
}
