@import '../../../styles/vendor/hel-components/variables';

.helerm-classification-label {
  vertical-align: text-bottom;
  margin-right: 12px;
}

.helerm-classification-selector {
  display: inline-block;
  min-width: 400px;
  vertical-align: bottom;

  .Select-value-label {
    color: $link-color;
  }
}
