.header {

  .brand-title {
    float: left;
  }

  .navbar-brand {
    color: #fff !important;
    padding: 10px 5px !important;
    line-height: 40px !important;
    font-size: 18px !important;
    &.logo {
      margin-right: 15px;
    }
    svg {
      width: 75px;
      fill: #fff;
      vertical-align: middle;
    }
  }

  .login-link {
    small {
      padding-right: 8px;
    }
  }
}

@media (max-width: 800px) {
  .login-link {
    small {
      display: none;
    }
  }
}