@import '../../../styles/vendor/hel-components/variables';

.add-box {
  padding: 16px 12px 20px;
  border: 2px dotted $brand-primary;
  background-color: $gray-lighter;
  border-radius: 4px;
  margin: 25px 0px;
}

.action > .add-box {
  margin: 0;
}

.editor-form {
  &__checkbox {
    position: absolute;
    left: 0;
    top: 0;
  }
  &__label {
    padding-left: 10px;
  }
  &__submit, &__cancel {
    margin-left: 10px;
  }

  .non-display {
    display: none;
  }

  .descriptions {
    min-height: 90px;
  }

  .edit-record__input:not(.additional-information) {
    height: 36px;
    margin-bottom: 10px;
  }

  .Select {
    padding: 0;
    border: none;
    margin-bottom: 10px;
    height: auto;

    .Select-control {
      border: 2px solid #dce4ec;
      border-radius: 0;
      margin: 0;
    }
  }

  .react-datepicker__input-container {
    display: block;

    a {
      position: absolute;
      right: 5px;
      top: 50%;
    }
    .edit-record__input:not(.additional-information) {
      margin-bottom: 0;
    }
  }
}

.general-info {
  .add-box {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.no-fields {
  font-size: 22px;
  margin: 22px 0 18px;
  text-align: center;
}
