@import '../../../../styles/vendor/hel-components/variables';

.preview {
  padding: 15px 30px;
}

.preview-actions {
  border-bottom: 1px solid $hr-border;
  padding-bottom: 15px;
  padding-top: 15px;
  
  button {
    float: right;
    margin-left: 15px;
  }
}

.preview-item {
  padding: 15px;
  border-bottom: 1px solid $hr-border;

  &-check {
    display: inline-block;
    color: $hr-border;
    padding: 8px 10px;
    border: 1px solid $hr-border;
  }

  &-checked {
    background-color: $brand-primary;
    border: 1px solid $brand-primary;
    color: $white;
  }

  .fa-exclamation-triangle {
    color: $brand-danger;
    margin-right: 15px;
  }
}

.preview-item-path {
  display: block;
  color: #999;
}

.preview-item-name {
  font-weight: normal;
}

.preview-changes h4 span {
  color: #999;
  text-decoration: line-through;
}

.preview-errors {
  h4, p {
    color: $brand-danger;
  }
  .preview-error-phase {
    padding-left: 15px;
  }
  .preview-error-action {
    padding-left: 30px;
  }
  .preview-error-record {
    padding-left: 45px;
  }
}