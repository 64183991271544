@import '../../../../styles/vendor/hel-components/variables';

.conversion {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding:  30px 0;
  border-top: 1px solid $hr-border;

  > div {
    flex: 1 1 auto;
    padding-right: 15px;
  }

  > div.conversion-action {
    padding-right: 0;
    flex: 0 0 60px;
    text-align: right;
  }

  .form-control {
    z-index: 9;
    height: 34px;
    padding: 0;
    border: 1px solid #dce4ec;
  }

  .conversion-label {
    display: block;
    font-weight: bold;
  }

  .conversion-date-field {
    input {
      height: 34px;
    }
  }
}