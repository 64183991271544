@import '../../../styles/vendor/hel-components/variables';

.clone__view {
  .importable-elements {
    padding-top: 0;
    overflow: auto;
  }

  .nav-tabs {
    padding: 15px 0;

    > li > a {
      cursor: pointer;
    }
  }

  .clone-controls {
    margin-top: 15px;

    .btn {
      margin-left: 1px;
    }
  }

  .import-button {
    color: $brand-primary;
    margin-right: 15px;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .helerm-navigation .navigation-menu {
    .navigation-header {
      display: none;
    }

    .navigation-container {
      position: static;

      .navigation-toggle {
        display: none;
      }
    }
  }
}
