@import '../../../styles/vendor/hel-components/variables';

.phase {
  padding-bottom: 40px;
  > .box {
    display: inline-block;
    width: 100%;
    background-color: $gray-lighter;
  }
}
.phase-title {
  z-index: 2;
  position: relative;
  pointer-events: none;
  * {
    pointer-events: all;
  }
  &-input {
    margin-right: 15px;
  }
}
.phase-title-sticky {
  background-color: $gray-lighter;
  z-index: 8;
  margin-left: 0;
  width: 100% !important;
  left: 0px !important;
  padding: 0 46px;
  border-bottom: 1px solid #cfcfcf;
  .basic-attributes {
    padding-bottom: 0;
  }
}
.validation-bar-open {
  .phase-title-sticky {
    background-color: $gray-lighter;
    z-index: 8;
    margin-left: 0;
    width: 75% !important;
    left: 0px !important;
    padding: 0 46px;
    border-bottom: 1px solid #cfcfcf;
    .basic-attributes {
      padding-bottom: 0;
    }
  }
}
.title-edit-button {
  margin-left: 8px;
}
.input-title {
  font-size: 1em;
}
.input-type {
  font-size: 1em;
}
.actions {
  padding: 0px 5px;
}
.phase-buttons {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 3;
}
.phase-buttons button {
  margin: 0px 1px;
}
.edit-phase-type__input {
  &.form-control {
    padding: 0;
    height: 34px;
  }
  &.Select.form-control {
    height: 43px;
    margin-bottom: 0;
    background-color: transparent;
  }
  margin-bottom: -10px;
  height: 31px;
  padding: 0;
  border: 0;

  .Select-control {
    height: 41px;
    border-radius: 2px;
  }
}

.phase-attributes {
  display: inline-block;
  width: 100%;
  margin-bottom: 0;
  position: relative;

  .basic-attributes {
    display: inline-block;
    width: 100%;

    .phase-basic-attribute {
      color: $brand-info;
    }
  }

  .attribute {
    font-size: 15px;
    min-height: 80px;
    border: none;
    padding: 15px 0;
  }

  .edit-attribute__input {
    border: 1px solid #aaa;
  }

  .phase-title-dropdown {
    position: relative;
    z-index: 5;
  }
}

.phase {
  .add-element {
    margin: 15px 5px;
  }
}
