@import '../../../styles/vendor/hel-components/variables';

.faceted-search-help {
  position: relative;

  button {
    border: solid 1px $black50;
    border-radius: $border-radius-large;
    margin-left: 15px;
  }

  button:active,
  button:focus,
  button:hover {
    border: solid 1px $black50;
  }

  .popover {
    padding: 15px;
    top: 110%;
    max-width: 300px;
    width: 300px;
    margin-left: -120px;
  }

}